import React, { useContext } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
// import { useTranslation } from "react-i18next";

import { makeStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  ButtonBase,
  Link,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { ThemeProvider, createTheme } from "@mui/system";

import logo from './Mentor_Logo_Claim.png';

// import { useMediaQuery } from "react-responsive";
// import { GlobalContext } from "../store/contexts/Contexts";

const headerLinks = [
  {
    label: "Home",
    href: "/",
  },
  {
    label: "Coaches ",
    href: "https://app.mentor-app.de/coaches",
  },
  {
    label: "Coachfinder",
    href: "https://app.mentor-app.de/coach-finder",
  },

];

// const languageData = [
//   {
//     label: "English",
//     value: "en",
//   },
//   {
//     label: "German",
//     value: "de",
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  menuButton: {
    marginRight: 2,
    color: "black",
    fontSize: "1.0rem",
    fontWeight: 800,
  },
  langButton: {},
  backBtn: {
    color: "grey",
    justifyContent: "left",
    height: "100%",
  },
  title: {
    color: "black",
    fontSize: "1.3rem",
    fontWeight: 800,
  },
  
}));

export default function Header({ title }) {

  const classes = useStyles();
  // const { i18n } = useTranslation();
  // const isMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 480px)",
  // });
  // const isTabletOrMobileDevice = useMediaQuery({
  //   query: "(max-device-width: 1366px)",
  // });

  //const { usersState, goBack } = useContext(GlobalContext);

  // distinguish login / logout
 

  const history = useHistory();

  // const handleLanguageSelection = (e) => {
  //   i18n.changeLanguage(e.currentTarget.value);
  // };

  const getMenuButtons = () => {
    return headerLinks.map(({ label, href }) => {
      return (
        <Button href={href} >
         
          {label}
        </Button>
      );
    });
  };

  // const getLangButtons = () => {
  //   return languageData.map(({ label, value }) => {
  //     return (
  //       <Button
  //         key={value}
  //         className={classes.langButton}
  //         value={value}
  //         onClick={handleLanguageSelection}
  //       >
  //         {label}
  //       </Button>
  //     );
  //   });
  // };



  const desktopHeader = () => {
    return (
      <Box display="flex" p={1} width="100%" >
        <Box flexGrow={1} alignItems="center" display="flex"  style={{minHeigth:"100px"}}>
        <Link href="/"><img src={logo} style={{maxHeight: "80px"}} /></Link>
          <Typography className={classes.title} align="left">
            {title}
          </Typography>
        </Box>
        <Box style={{display: "flex", alignItems:"center", justifyContent: "center"}}>{getMenuButtons()}</Box>
        <Box style={{display: "flex", alignItems:"center", justifyContent: "center"}}>
          <Button
            href="https://www.mentor-app.de"
            className={classes.menuButton}
            >
            Zur Mentor Seite
          </Button>
        </Box>
        {/* {getLangButtons()} */}
      </Box>
    );
  };

  return (

    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "white" }}>
        <Toolbar>
          {desktopHeader()}
        </Toolbar>
      </AppBar>
    </div>

  );
}
