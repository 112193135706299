import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);


  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const handleClose = () => {
    props.handleClose()
  };

  return (
    <div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ marginLeft: 20, marginTop: 20 }}>
          { props.text.title ? props.text.title : "Title"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description"  style={{ padding: 20, whiteSpace: 'pre-line' }} >
               {props.text.text ? props.text.text : "Alert-Content"}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button onClick={handleClose}>{props.text.btnText ? props.text.btnText : "OK, mache ich"}</Button>
          
        </DialogActions>
      </Dialog>
    </div>
  );
}