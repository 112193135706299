import logo from './logo.svg';
import cm1_2 from './1cm2.png';
import './App.css';
import { styled } from '@mui/material/styles';

import { motion, useCycle } from "framer-motion";
import { useState, useRef } from 'react';
import AlertDialog from './AlertDialog';
import { Button, Box, Paper, Grid, Typography } from '@mui/material';
import Header from './Header';
import { ThemeProvider } from '@mui/material/styles';

import theme from './theme';

import ReactGA from 'react-ga4';

ReactGA.initialize('G-P4B7DF84XB', {
  debug: true });



function Item (props) {

  const variants = {
    open: {
      y: 0,
      opacity: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 }
      }
    },
    closed: {
      y: 50,
      opacity: 0,
      
    }
  };

  const element = props.element;
  const idx = props.idx;
  const onClick = props.onClick;

  return (
    
  <motion.div 
    variants={variants}

    whileHover={{ scale: 1.1 }}
    whileTap={{ scale: 0.95 }}

    style={{
    
      backgroundImage: `url(https://beta.mentor-app.de/massband/1cm2.png)`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: "contain",
      position: "relative",
      textAlign: 'center',
      backgroundColor: element.style, 
      minHeight: 50, 
      minWidth: 75,
     }} 
     onClick={() => onClick(idx)} >
     <div style={{ 
        backgroundColor: element.style,
        position: "absolute",
  
        width: "100%",
        height: "100%",
        
      }}>
      <div style={{ 
        
        position: "absolute",
        bottom: "25%",
        left: "8%",
        fontSize: "14px",
        
      }}>{idx}</div>
    </div>
  </motion.div>);
};




const variants = {
  open: {
    
    transition: { staggerChildren: 0.05, delayChildren: 0 }
  },
  closed: {
    
    opacity: 0,
    // transition: { staggerChildren: 0.005, staggerDirection: -1 }
  }
};




function App() {

  ReactGA.send("pageview");

  const [isOpenAlert, toggleOpenAlert] = useCycle(false, true);

  const containerRef = useRef(null);

  const defaultItems = () => {
    return Array(126).fill({ style: "rgba(255, 255, 255, 0.0)" })
  }

  const actions = ['die', 'lived', 'reset'];
  const [nextAction, setNextAction] = useState("animate");
  const [items, setItems] = useState(defaultItems())
  const [alertText, setAlertText] = useState({text: "", title: ""})
  const [started, setStarted] = useState(false)

  const [isOpen, toggleOpen] = useCycle(false, true);

  const act = (i) => {

    ReactGA.event({
      category: "action",
      action: nextAction,
      value: i, // optional, must be a number
    });

    if (nextAction === "animate") {
      
      setStarted(true);
      toggleOpen();
      setNextAction("die");
      setAlertText({title: "Der erste Schritt", text: 
      `Im Hintergrund siehst du das Maßband der Jahreszahlen deines Lebens.

      Unser erster Impuls für dich: Wähle das Jahr, in dem du wahrscheinlich nicht mehr da sein wirst. Statistisch gesehen wird das ca das achzigste Lebensjahr sein. Womit rechnest du? 
      Mehr? 
      Weniger? 
      Was für Gefühle kommen hoch, wenn du das entscheiden sollst?`,
      btnText : "Ok, mache ich." })
      toggleOpenAlert()
    }
    if (nextAction === "die") {
      die(i);
      setNextAction("lived");
      setTimeout( () => {
          setAlertText({title: "Super!", text: 
          `Nächster Schritt: Wähle dein aktuelles Alter aus. 
          
          Das was 'weiß' bleibt, sind die Jahre, die du wahrscheinlich noch leben wirst. Was geht dir durch den Kopf, wenn du das siehst?`,
          btnText : "Ok, mache ich." })
          toggleOpenAlert();
        }
        , 2000);
      
    }
    if (nextAction === "lived") {
      lived(i);
      setNextAction("reset");
      setTimeout( () => {
          setAlertText({title: "Danke fürs mitmachen!", text: 
          `Wie war das für dich? 
          Die meisten Teilnehmer finden es doch überraschend so klar zu sehen, wieviel Zeit  noch übrig ist... 
          Das wird in Motivations-Seminaren gerne zur Motivation eingesetzt, die verbleibene Zeit doch wirklich 'FÜR MICH' zu nutzen. 

          Doch viel wichtiger finden wir die andere Seite der Medaille: Guck mal wie weit du es geschafft hast! Trotz aller Widrigkeiten und Herausforderungen: Du bist bis hierhin gekommen, viele andere können das nicht sagen.
          Du hattest also die Fähigkeit dich an all die Herausforderungen anzupassen. Und das hast du nicht geschafft, indem du einfach immer dasselbe getan hast, sondern in dem du immer wieder andere deiner Fähigkeiten gewählt hast.

          Veränderung kommt immer von innen. Und manchmal ist es an der Zeit eine andere unserer Fähigkeiten zu wählen und zu bestärken.

          Nur ist uns manchmal der Blick verstellt darauf, was ich alles schon kann - dann kann ein Spiegel: ein Gesprächspartner oder ein Coach uns wieder neue Türen öffnen.
          
          Braucht man diesen Spiegel nur in der Krise?
          Gegenfrage: Wie fändest du es, du weisst schon vorher, wie und wann du welche Fähigkeiten wählst - bevor die nächste Krise kommt?
          
          `, btnText : "Ende&Anfang"})
          toggleOpenAlert();
        }
        , 3000);
      
    }
    if (nextAction === "reset") {
      setItems(defaultItems());
      setAlertText({title: "Letzter Schritt", text: "Noch mal von vorne? "})
      //setAlertText("Wähle das Jahr aus, in dem du vermutlich nicht mehr da bist.")
      setNextAction("animate");
      if (isOpen) toggleOpen();
      setStarted(false)
    }
  }

  const lived = (dieIdx) => {
    //const newItem = {...items[dieIdx], style:"die" }
    const newItems = items.map((e, idx) => {
      const afterNewItem = idx <= dieIdx ? { ...e, style: "rgba(0, 255, 0, 0.4)" } : e;
      return afterNewItem;
    })
    setItems(newItems);
  }


  const lived2 = (dieIdx) => {
    //const newItem = {...items[dieIdx], style:"die" }
    const newItems = items.map((e, idx) => {
      const afterNewItem = idx <= dieIdx ? { ...e, style: "rgba(0, 255, 0, 0.4)" } : e;
      return afterNewItem;
    })
    setItems(newItems);
  }

  const die = (dieIdx) => {
    //const newItem = {...items[dieIdx], style:"die" }
    const newItems = items.map((e, idx) => {
      const afterNewItem = idx >= dieIdx ? { ...e, style: "rgba(0, 0, 0, 0.4)" } : e;
      return afterNewItem;
    })
    setItems(newItems);
  }



  return (
    <ThemeProvider theme={theme}>
    <div className="App" style={{position: "relative",  position: "relative",}} >
      <Header />

      

        <Box sx={{  display:"flex", 
                  flexGrow: 1, 
                  top: "100px", 
                  position: "absolute",
                  maxHeight : "calc(100vh - 100px);"  }}>
        
        <motion.div 
          ref={containerRef}
          animate={isOpen ? "open" : "closed"}
          variants={variants} 
          style={{ display: "flex", flexWrap: "wrap" }} >
          {items.map((e, i) => <div item xs={1} ><Item onClick={() => act(i)} idx={i} element={e}/></div>)}
        </motion.div>
        {/* <Grid container spacing={1}>
              {items.map((e,i) => <Grid item xs={1} ><Item onClick={() => act(i)} style={{backgroundColor: e.style, minHeight: 50 }}>{i}</Item></Grid>)}
          </Grid> */}
      </Box>

      {!started && <Box sx={{ 
                    backgroundColor: "white",
                    display:"flex",  
                    flexDirection: "column", 
                    flexGrow: 1, 
                    position: "relative", 
                    minHeight : "calc(100vh - 100px);", 
                    justifyContent:"center", 
                    alignItems: "center"  }}>
        <Typography variant="h3" component="div" gutterBottom>
          Maßband des Lebens
        </Typography>
        <Typography variant="h4" component="div" gutterBottom>
          ein Reflektionstool
        </Typography>
        <Typography variant="body1" component="div" gutterBottom>
           
        </Typography>
        <div style={{ display: "flex", flexWrap: "wrap", marginBottom: "20px" }} ></div>
        <Button onClick={() => act()} style={{}}>Zum Starten, hier klicken</Button>
      </Box>}

      <AlertDialog text={alertText} handleClose={() => toggleOpenAlert()} open={isOpenAlert} />
    </div>
    </ThemeProvider>
  );
}

export default App;
